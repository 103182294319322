<template>
    <div class="app-container">
        <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="馆组信息" name="first">
                <div class="library-details"  v-if="editLibrary == false">
                    <div class="left">
                        <div class="txt">馆组名称：</div>
                        <div class="txt">所属馆组：</div>
                        <div class="txt">分馆类型：</div>
                        <div class="txt">分馆地址：</div>
                        <div class="txt">联系电话：</div>
                        <div class="txt">营利性质：</div>
                        <div class="txt">创建时间：</div>
                        <div class="txt">到期时间：</div>
                        <div class="txt">分馆介绍：</div>
                    </div>
                    <div class="right">
                        <div class="txt1">{{ libraryDetailsList.branchName }}</div>
                        <div class="txt1">{{ libraryDetailsList.groupName }}</div>
                        <div class="txt1">{{ libraryDetailsList.branchType == '0' ? '学校馆' : '社区馆' }}</div>
                        <div class="txt1">{{ libraryDetailsList.branchPosition }}</div>
                        <div class="txt1">{{ libraryDetailsList.phone }}</div>
                        <div class="txt1">{{ libraryDetailsList.profitableNature == '0' ? '公益馆' : '商业馆' }}</div>
                        <div class="txt1">{{ libraryDetailsList.createTime.substring(0,10) }}</div>
                        <div class="txt1">{{ libraryDetailsList.expireTime.substring(0,10) }}</div>
                        <div class="txt1">{{ libraryDetailsList.branchIntro }}</div>
                    </div>
                    <el-button type="primary" @click="editLibrary = true" style="height: 40px;">编辑</el-button>

                </div>
                <div v-if="editLibrary == true">
                    <!-- // 编辑按钮 -->
                    
                    <div style="float: right;"><el-button type="primary" @click="resetForm1('libraryDetailsList')">保存</el-button></div>
                    <div >
                        <el-form :model="libraryDetailsList"  :inline="true" label-width="110px" ref="libraryDetailsList" :rules="rules">
                            <el-form-item label="馆组名称：" prop="branchName">
                                <el-input v-model="libraryDetailsList.branchName" placeholder="请输入馆组名称"></el-input>
                            </el-form-item>
                            <el-form-item label="所属馆组：" prop="groupName">
                                <el-input v-model="libraryDetailsList.groupName" placeholder="请输入所属馆组"></el-input>
                            </el-form-item>
                            <el-form-item label="分馆类型：" prop="branchType">
                                <!-- <el-input v-model="libraryDetailsList.branchType" placeholder="请输入分馆类型"></el-input> -->
                                <el-radio v-model="libraryDetailsList.branchType" label="0">学校馆</el-radio>
                                <el-radio v-model="libraryDetailsList.branchType" label="1">社区馆</el-radio>
                            </el-form-item>
                            <el-form-item label="分馆地址：" prop="branchPosition">
                                <el-input v-model="libraryDetailsList.branchPosition" placeholder="请输入分馆地址"></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话：" prop="phone">
                                <el-input v-model="libraryDetailsList.phone" placeholder="请输入联系电话"></el-input>
                            </el-form-item>
                            <el-form-item label="营利性质：" prop="profitableNature">
                                <!-- <el-input v-model="libraryDetailsList.profitableNature" placeholder="请输入营利性质"></el-input> -->
                                <el-radio v-model="libraryDetailsList.profitableNature" label="0">公益馆</el-radio>
                                <el-radio v-model="libraryDetailsList.profitableNature" label="1">商业馆</el-radio>
                            </el-form-item>
                            <!-- <el-form-item label="创建时间：" prop="createTime">
                                <el-date-picker
                                    v-model="libraryDetailsList.createTime"
                                    type="datetime"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 206px;"
                                ></el-date-picker>
                            </el-form-item> -->
                            <el-form-item label="到期时间：" prop="expireTime">
                                <el-date-picker
                                    v-model="libraryDetailsList.expireTime"
                                    type="datetime"
                                    placeholder="选择日期"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    style="width: 206px;"
                                ></el-date-picker>
                            </el-form-item>
                            <el-form-item label="分馆介绍：" prop="branchIntro">
                                <el-input
                                    type="textarea"
                                    :rows="2"
                                    placeholder="请输入分馆介绍"
                                    v-model="libraryDetailsList.branchIntro"
                                ></el-input>
                            </el-form-item>
                           
                        </el-form>
                    </div>
                </div>
            </el-tab-pane>

            <el-tab-pane label="成员管理" name="second">
                <!-- 功能按钮组 -->
                <el-row :gutter="20" style="margin-bottom: 7px">
                    <el-col :span="1.5">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain
                            @click="handleAdd">添加分馆成员</el-button>
                    </el-col>
                </el-row>

                <!-- 表格数据 -->
                <el-table ref="table" :data="tableData" @selection-change="handleSelectionChange">
                    <el-table-column prop="createTime" label="加入时间" width="180px" />
                    <el-table-column prop="username" label="姓名" width="180px" />
                    <el-table-column prop="bid" label="角色权限" width="160px">
                        <template slot-scope="scope">
                            {{ scope.row.bid == 0 ? '总馆图书管理员' : '分馆图书管理员' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="电话号码" />
                    <!-- <el-table-column prop="email" label="邮箱" show-overflow-tooltip /> -->
                    <el-table-column label="操作" width="100px">
                        <template slot-scope="scope">
                            <div v-if="username == scope.row.username">
                                <el-button size="mini" icon="el-icon-delete" type="info" plain
                                >暂无</el-button>
                            </div>
                            <div v-else>
                                <el-button size="mini" icon="el-icon-delete" type="danger" plain
                                @click="handleDelete(scope.row)">移除</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="page.current" :page-size="page.size" :page-sizes="[2, 5, 10, 20, 100]" :page-count="page.pageNum"
                    layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                </el-pagination>
            </el-tab-pane>
            <el-tab-pane name="third" label="借阅规则管理">
                <el-row :gutter="20" style="margin-bottom: 7px">
                    <el-col :span="1.5">
                        <el-button type="primary" size="small" icon="el-icon-plus" plain
                            @click="dialogFormRuleVisible = true">添加借阅规则</el-button>
                    </el-col>
                </el-row>
                <el-table :data="ruleList" style="width: 100%">
                    <!-- <el-table-column prop="id" label="ID" width="80" /> -->
                    <el-table-column prop="name" label="规则名称" />
                    <el-table-column prop="lendDays" label="借阅天数" />
                    <el-table-column prop="lendNum" label="借阅本数" />
                    <el-table-column prop="createTime" label="创建时间" />
                    <el-table-column prop="updateTime" label="更新时间" />
                    <el-table-column prop="isDelete" label="状态" >
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.isDelete == 0" type="success">正常</el-tag>
                            <el-tag v-else type="danger">已删除</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="handleEditRule(scope.row)">编辑</el-button>
                            <el-button type="text" size="small" @click="handleDeleteRule(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>

                   
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <!-- 编辑借阅规则 -->
        <el-dialog :title="addRuleForm.name" append-to-body :modal="true" :visible.sync="editBranchRuleDialog" width="40%" >
            <el-form  ref="addRuleForm" :model="addRuleForm" :rules="addRuleFormRules" label-width="110px">
                <el-form-item label="借阅规则名称" prop="name">
                        <el-input v-model="addRuleForm.name" placeholder="请输入借阅规则名称" style="width:200px" />
                    </el-form-item>
                    <el-form-item label="借阅天数" prop="lendDays">
                        <el-input v-model.trim.number="addRuleForm.lendDays" placeholder="请输入借阅天数" style="width:200px" />
                    </el-form-item>
                    <el-form-item label="借阅本数" prop="lendNum">
                        <el-input v-model.trim.number="addRuleForm.lendNum" placeholder="请输入借阅本数" style="width:200px" />
                    </el-form-item>
                    <!-- <el-form-item label="备注" prop="remark">
                        <el-input v-model.trim="addRuleForm.remark" tabindex="3" placeholder="请输入备注" style="width:200px"/>
                    </el-form-item> -->
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editBranchRuleDialog = false">取 消</el-button>
                    <el-button type="primary" @click="submitEditRule('addRuleForm')">确 定</el-button>
                </div>
            
        </el-dialog>
        <!-- 添加借阅规则对话框 -->
        <el-dialog title="添加借阅规则" :modal="true" :visible.sync="dialogFormRuleVisible" width="40%" append-to-body>
                <el-form ref="addRuleForm" :model="addRuleForm" :rules="addRuleFormRules" label-width="110px" >
                    <el-form-item label="适用馆组" prop="branchIdList">
                        <el-select v-model="addRuleForm.branchIdList" multiple clearable @focus.once="changeBranchIdList()" placeholder="请选择适用馆组" style="width:200px">
                            <el-option
                                v-for="item in branchIdAndNameList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="借阅规则名称" prop="name">
                        <el-input v-model="addRuleForm.name" placeholder="请输入借阅规则名称" style="width:200px" />
                    </el-form-item>
                    <el-form-item label="借阅天数" prop="lendDays">
                        <el-input v-model.trim.number="addRuleForm.lendDays" placeholder="请输入借阅天数" style="width:200px" />
                    </el-form-item>
                    <el-form-item label="借阅本数" prop="lendNum">
                        <el-input v-model.trim.number="addRuleForm.lendNum" placeholder="请输入借阅本数" style="width:200px" />
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model.trim="addRuleForm.remark" tabindex="3" placeholder="请输入备注" style="width:200px"/>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormRuleVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitAddRule('addRuleForm')">确 定</el-button>
                </div>
        </el-dialog>
        <!-- 编辑或添加对话框 -->
        <el-dialog :title="dialogTitle" :modal="false" :visible.sync="dialogFormVisible" width="50%" append-to-body>
            <el-form ref="dialogForm" :model="dialogForm" label-width="80px" inline :rules="dialogFormRules">
                <el-form-item label="姓名" prop="username">
                    <el-input v-model="dialogForm.username" autocomplete="off" />
                </el-form-item>
                <el-form-item label="账号" prop="account" v-if="false" >
                    <el-input v-model="dialogForm.account" autocomplete="off"/>
                </el-form-item>
                <el-form-item v-show="dialogType == 'add'" label="密码" prop="password">
                    <el-input v-model="dialogForm.password" autocomplete="off" />
                </el-form-item>
                <el-form-item label="电话号码" prop="phone">
                    <el-input v-model="dialogForm.phone" maxlength="11" autocomplete="off" />
                </el-form-item>
                <el-form-item label="地址" prop="address" v-if="false">
                    <el-input v-model="dialogForm.address" autocomplete="off" />
                </el-form-item>
                <el-form-item label="邮箱" prop="email" v-if="false">
                    <el-input v-model="dialogForm.email" autocomplete="off" value="dialogForm.phone+'@163.com'" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog">取 消</el-button>
                <el-button type="primary" @click="submitDialog('dialogForm')">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>
  
<script>
import { listAdmin, addManager, deleteAdmin } from '@/api/admin'
import { libraryDetailsData, AdmindetailsData, libraryEditOne } from '@/api/library'
import { getLendRule,addLendRule,updateLendRule,deleteLendRule} from '@/api/lendRule'
//import {getAid,getBid} from  '@/api/library'
import { getUserName } from '@/utils/auth'

export default {
    data() {
        return {
            //rules:[],
            ruleList:[],
            // 显示创建借阅规则
            dialogFormRuleVisible:false,
            // 添加借阅规则
            addRuleForm:{
                branchIdList:[],
                lendDays:undefined,
                lendNum:undefined,
                name:'',
                relendDays:7,
                remark:''
            },
            branchIdAndNameList:[],
            editBranchRuleDialog:false,
            //显示编辑框
            editLibrary: false,
            //添加验证信息
            addRuleFormRules: {
                branchIdList: [{ required: true, type: 'array', message: '请选择至少一个分馆', trigger: 'change' }],
                lendDays: [{ required: true, type: 'number', message: '请输入有效的借阅天数', trigger: 'blur' }],
                lendNum: [{ required: true, type: 'number', message: '请输入有效的借阅数量', trigger: 'blur' }],
                name: [{ required: true, type: 'string', min: 1, message: '姓名不能为空且至少一个字符', trigger: 'blur' }]
            },
            dialogFormRules:{
                username: [{required: true, message: '名字不能为空', trigger: 'blur'}],
                password:  [{required: true, message: '密码密码不能为空 ', trigger: 'blur'}],
                phone:     [{required: true,message: "手机号不能为空",trigger: "blur"},
                            {pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: "请输入正确的手机号码", trigger: "blur"}]
            },

            // =============馆组信息===========
            editFlag: false,
            LabMsg: [],
            // 总馆详情
            details: {},
            //当前官族的人员名字
            username:"",


            // 馆组信息
            libraryDetailsList: {
                branchIntro:'',
                branchName:'',
                branchPosition:'',
                branchType:'',
                createTime:'',
                expireTime:'',
                groupName:'',
                phone:'',
                profitableNature:'',
                availableNum: 3,
                availableTime: 7,
            },
            // 查询表单数据
            queryForm: {},
            // 按钮禁用
            editDisabled: true,
            removeDisabled: true,
            // 表格数据
            tableData: [],
            // 多选数据
            multipleSelection: [],
            // 分页数据
            page: {
                current: 1, // 当前页
                size: 10, // 每页显示数
                total: undefined , // 总计
                pageNum:1 //页数
            },
            // 对话框类型[add|edit]
            dialogType: '',
            // 对话框是否可见
            dialogFormVisible: false,
            // 对话框标题
            dialogTitle: undefined,
            // 对话框数据
            dialogForm: {
                account: "",
                email: "",
                password: "",
                phone: "",
                username: ""
            },
            activeName: "first",
            rules: {
        branchName: [
          { required: true, message: '馆组名称不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符之间', trigger: 'blur' },
        ],
        groupName: [
          { required: true, message: '所属馆组不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符之间', trigger: 'blur' },
        ],
        branchType: [
          { required: true, message: '分馆类型不能为空', trigger: 'blur' },
        ],
        branchPosition: [
          { required: true, message: '分馆地址不能为空', trigger: 'blur' },
          { min: 5, max: 100, message: '长度在 5 到 100 个字符之间', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' },
        ],
        profitableNature: [
          { required: true, message: '营利性质不能为空', trigger: 'blur' },
        ],
        createTime: [
          {  required: true, message: '请选择创建时间', trigger: 'change' },
        ],
        expireTime: [
          {  required: true, message: '请选择到期时间', trigger: 'change' },
        ],
        branchIntro: [
          { required: true, message: '分馆介绍不能为空', trigger: 'blur' },
          { min: 10, max: 500, message: '长度在 10 到 500 个字符之间', trigger: 'blur' },
        ],
      },
        }
    },
    created() {
        // this.Admindetails();
        this.librariesDetails()
        
        this.getList()
        this.username = JSON.parse(getUserName())
        this.getLendRuleBranch()
    },
    updated(){
        
       if (this.tableData == []){
        const queryParams = { ...this.queryForm, page:1, size: this.page.size }
            listAdmin(queryParams).then(({ data }) => {
                this.tableData = data.adminData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageNum = data.pageNum
            })
       }
    },
    methods: {
        //=============借阅规则=============
        getLendRuleBranch(){

            getLendRule().then((res) => {
                //console.log(res)
                this.ruleList = res.data
            })
        },
        changeBranchIdList(){
            //console.log("wooo")
            libraryDetailsData().then((res) => {
                this.branchIdAndNameList.push({value:res.data.id,label:res.data.branchName})
            })
        },
        submitAddRule(ref){
            this.$refs[ref].validate((valid,value) => {
                if(valid){
                    //console.log(this.addRuleForm)
                    const params = {...this.addRuleForm}
                    addLendRule(params).then((res) => {
                        if(res.code == 200){
                            this.dialogFormRuleVisible=false
                            this.$message.success("添加成功")
                            this.addRuleForm = {}
                            this.addRuleForm.relendDays = 7
                            this.getLendRuleBranch()

                        }
                       
                    })
                }else{
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                }
            })
        },
        handleEditRule(row){
            //console.log(row)
            this.addRuleForm = row
            delete this.addRuleForm.createTime
            delete this.addRuleForm.updateTime
            this.editBranchRuleDialog = true
            libraryDetailsData().then(
                res =>{
                    this.addRuleForm.branchIdList =[]
                   this.addRuleForm.branchIdList.push(res.data.id)
                }
            )

        },
        submitEditRule(ref){
            
            
            this.$refs[ref].validate((valid,value) => {
                if(valid){
                    //console.log(this.addRuleForm)
                    this.addRuleForm.relendDays = 7
                    const params = {...this.addRuleForm}
                    console.log(params)
                    updateLendRule(params).then((res) => {
                        if(res.code == 200){
                            this.editBranchRuleDialog=false
                            this.$message.success("修改成功")
                            this.addRuleForm = {}
                            this.addRuleForm.relendDays = 7
                            this.getLendRuleBranch()

                        }else{
                            this.$message.error(res.data.message)
                        }
                       
                    })
                }else{
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                }
            })
        },
        handleDeleteRule(row){
            let ids = []
            ids.push(row.id)
            deleteLendRule(ids).then(res=>{
                if(res.code == 200){
                    this.$message.success('删除成功')
                    this.getLendRuleBranch()
                }else{
                    this.$message.error('删除失败')
                }
            })
        },
        //修改馆组信息
        resetForm1(libraryDetailsList){
            
           //console.log(this.$refs[libraryDetailsList]) 
           this.$refs[libraryDetailsList].validate((valid, value) => {
                if(valid){
                    //发送请求
                   //console.log(this.libraryDetailsList)
                //    this.libraryDetailsList.branchType == '0' ? this.libraryDetailsList.branchType = '学校馆' : this.libraryDetailsList.branchType = '社区馆'
                //    this.libraryDetailsList.profitableNature == '0' ? this.libraryDetailsList.profitableNature = '公益馆' : this.libraryDetailsList.profitableNature = '商业馆'
                    const params = {...this.libraryDetailsList , availableNum: 30,availableTime: 30,}
                    libraryEditOne(params).then((res)=>{
                        if(res.code == '200'){
                            this.$message.success("修改成功")
                            this.librariesDetails()
                            this.editLibrary = false
                        }else{
                            alert("修改失败")
                            this.editLibrary = true
                        }
                    })
                }else{
                   // console.log('11111')
                    Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
                }

           })
            
        },
        
        // ================馆组信息=============
        editMsg() {
            this.editFlag = true
        },
        editMsgConfirm() {
            let queryData = { ...this.LabMsg };
            libraryEdit(queryData).then(res => {
                if (res.code == "200") {
                    alert("修改成功");
                    this.Admindetails()
                    this.editFlag = false
                }
            })
        },
        editMsgReturn() {
            this.editFlag = false
        },

        // 馆组信息
        Admindetails() {
            AdmindetailsData({}).then(({ data }) => {
                this.details = data;
                // 需要修改的馆组信息
                this.LabMsg = data;
            })
        },





        //============== 分馆API =============
        // 馆组信息
        librariesDetails() {
            libraryDetailsData().then(({ data }) => {
                console.log(data);
                
                this.libraryDetailsList = data;
                this.libraryDetailsList.branchType == "学校馆" ? this.libraryDetailsList.branchType='0' : this.libraryDetailsList.branchType='1'
                this.libraryDetailsList.profitableNature == '公益馆' ? this.libraryDetailsList.profitableNature='0' : this.libraryDetailsList.profitableNature='1'
                console.log(this.libraryDetailsList.expireTime.length)
                this.libraryDetailsList.expireTime.length == '10' ? this.libraryDetailsList.expireTime = this.libraryDetailsList.expireTime+" 12:00:00" : this.libraryDetailsList.expireTime
                console.log(this.libraryDetailsList)
                // 需要修改的馆组信息
                this.LabMsg = data;
            })
        },
        // 查询分馆列表
        getList() {
            const queryParams = { ...this.queryForm, page: this.page.page, size: this.page.size }
            listAdmin(queryParams).then(({ data }) => {
                this.tableData = data.adminData
                this.page.total = data.total
                this.page.current = data.current
                this.page.pageNum = data.pageNum
            })
        },
        // 添加分馆
        addData() {
            //console.log(this.dialogForm)
            //const params = {}
            this.dialogForm.account=this.dialogForm.phone
            this.dialogForm.email = this.dialogForm.phone+"@163.com"
          
            addManager(this.dialogForm).then(({ data }) => {
                //console.log(data)
                if (data.code == 'A401'){
                    alert(data.message)
                    this.dialogFormVisible = true
                }else{
                    this.msgSuccess(data)
                    this.getList()
                    this.dialogFormVisible = false
                }
                   
                

                
                
            })
          
        },
        // 修改分馆
        updateData() {
            updateReader(this.dialogForm).then(() => {
                this.msgSuccess('修改成功!')
                this.getList()
            })
        },
        // 删除分馆
        deleteData(arr) {
            deleteAdmin(arr).then(({ data }) => {
                this.msgSuccess(data)
                this.getList()
            })
        },

        //============== 查询表单 =================
        // 查询表单提交
        querySubmit() {
            this.getList()
        },
        // 重置表单
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.getList()
        },

        //============== 表格 ==================
        // 多选改变
        handleSelectionChange(val) {
            this.multipleSelection = val
            this.editDisabled = !(val.length === 1)
            this.removeDisabled = !(val.length > 0)
        },
        // 删除行数据
        handleDelete(row) {
            //console.log(row.username+"===============")
            let arr = [row.id]
            this.$confirm('此操作将永久移除该成员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.deleteData(arr)
                })
                .catch(() => { })
        },
        // 添加数据
        handleAdd() {
            // 清除对话框表单数据
            this.dialogForm = {}
            this.dialogType = 'add'
            this.dialogTitle = '添加分馆图书管理员'
            this.showDialog()
        },
        // 编辑数据
        handleEdit(val) {
            this.dialogForm = val
            this.dialogType = 'edit'
            this.dialogTitle = '修改分馆'
            this.showDialog()
        },

        //============== 添加或编辑对话框 ===================
        // 显示对话框
        showDialog() {
            this.dialogFormVisible = true
        },
        // 对话框取消
        cancelDialog() {
            this.dialogFormVisible = false
        },
        // 对话框提交
        submitDialog(dialogForm) {
            if (this.dialogType == 'add') {
                
                this.$refs[dialogForm].validate((valid,value) => {
                   // console.log(valid+"=====")
                    if (valid) {
                        
                        this.addData()
                    } else {
                        //console.log('error submit!!');
                        //return false;
                        Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)})  
                        
                      
                    }
                    });


                
            } else {
                this.updateData()
            }
            this.dialogFormVisible = true
        },

        //=============== 分页 ==================
        // 每页显示数改变
        handleSizeChange(val) {
            this.page.size = val
            this.getList()
        },
        // 当前页改变
        handleCurrentChange(val) {
            this.page.page = val
            this.getList()
        }
    }
}
</script>
  
<style scoped>
.el-dialog > .el-dialog__body > .el-form {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
  
}
.library-details .left {
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.library-details .right {
    margin-right: auto;
    display: flex;
    flex-direction: column;
}

.txt {
    color: #858585;
    line-height: 2.5rem;
    font-size: 14px;
    display: flex;
    text-align: right;
    margin-right: 20px;
}

.txt1 {
    line-height: 2.5rem;
    font-size: 14px;
    text-align: right;
    min-width: 150px;
}

.setting {
    font-weight: 700;
    font-size: 16px;
    color: #17233d;
}

.setting-item {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    font-size: 14px;
    margin-right: 20px;
}

.bottom {
    position: fixed;
    bottom: 0;
    padding: 15px 30px;
    border: 1px solid #DCDFE6;
    background-color: #FFF;
    border-radius: 5px;
    width: 79%;
}
</style>