import request from '@/utils/request'

//查询馆组借阅规则
export function getGroupLendRule(){
    return request({
        url: '/lendRule/findGroup',
        method: 'get',
       
    })
}


//查询分馆借阅规则

export function getLendRule(){
    return request({
        url: '/lendRule/findBranch',
        method: 'get',
        
    })
}

//添加
export function addLendRule(data){
    return request({
        url: '/lendRule/add',
        method: 'post',
        data
    })
}

//编辑
export function updateLendRule(data){
    return request({
        url: '/lendRule/edit',
        method: 'post',
        data
    })
}

//删除
export function deleteLendRule(data){
    return request({
        url: '/lendRule/delete',
        method: 'post',
        data
    })
}